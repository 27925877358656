// src/components/VotesTable.tsx
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface Vote {
  ActorFractie: string;
  FractieGrootte: number;
  Soort: string;
  Vergissing?: boolean;
}

interface VotesTableProps {
  votes: Vote[];
}

const VotesTable: React.FC<VotesTableProps> = ({ votes }) => {
  const sortedVotes = [...votes].sort(
    (a, b) => b.FractieGrootte - a.FractieGrootte
  );
  const hasVergissing = sortedVotes.some(
    (vote) => vote.Vergissing !== undefined
  );

  return (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Fractie</TableCell>
            <TableCell align="right">Aantal zetels</TableCell>
            <TableCell align="right">Stem</TableCell>
            {hasVergissing && <TableCell align="right">Vergissing</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedVotes.map((vote, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {vote.ActorFractie}
              </TableCell>
              <TableCell align="right">{vote.FractieGrootte}</TableCell>
              <TableCell align="right">{vote.Soort}</TableCell>
              {hasVergissing && (
                <TableCell align="right">
                  {vote.Vergissing ? "vergissing" : ""}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VotesTable;
