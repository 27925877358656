import React, { useState, useEffect } from "react";
import {
  Paper,
  TextField,
  Button,
  Box,
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { fetchUniqueParties, fetchUniqueIndieners } from "../services/api";

interface SearchFormProps {
  onSearch?: (
    query: string,
    filters: { [key: string]: string | string[] }
  ) => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ onSearch }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [query, setQuery] = useState<string>(searchParams.get("text") || "");
  const [startDate, setStartDate] = useState<Dayjs | null>(
    searchParams.get("startDate") ? dayjs(searchParams.get("startDate")) : null
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    searchParams.get("endDate") ? dayjs(searchParams.get("endDate")) : null
  );
  const [votedFor, setVotedFor] = useState<string[]>(
    searchParams.get("voted_for")
      ? searchParams.get("voted_for")!.split(",")
      : []
  );
  const [votedAgainst, setVotedAgainst] = useState<string[]>(
    searchParams.get("voted_against")
      ? searchParams.get("voted_against")!.split(",")
      : []
  );
  const [resultBoolean, setResultBoolean] = useState<string>(
    searchParams.get("result_boolean") || ""
  );
  const [indiener, setIndiener] = useState<string>(
    searchParams.get("indiener") || ""
  );
  const [parties, setParties] = useState<string[]>([]);
  const [indieners, setIndieners] = useState<string[]>([]);
  const [loadingParties, setLoadingParties] = useState<boolean>(true);
  const [loadingIndieners, setLoadingIndieners] = useState<boolean>(true);

  useEffect(() => {
    const loadPartiesAndIndieners = async () => {
      try {
        const partiesData = await fetchUniqueParties();
        setParties(partiesData);
      } catch (err) {
        console.error("Failed to fetch unique parties:", err);
      } finally {
        setLoadingParties(false);
      }

      try {
        const indienersData = await fetchUniqueIndieners();
        setIndieners(indienersData);
      } catch (err) {
        console.error("Failed to fetch unique indieners:", err);
      } finally {
        setLoadingIndieners(false);
      }
    };

    loadPartiesAndIndieners();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setQuery(searchParams.get("text") || "");
    setStartDate(
      searchParams.get("startDate")
        ? dayjs(searchParams.get("startDate"))
        : null
    );
    setEndDate(
      searchParams.get("endDate") ? dayjs(searchParams.get("endDate")) : null
    );
    setVotedFor(
      searchParams.get("voted_for")
        ? searchParams.get("voted_for")!.split(",")
        : []
    );
    setVotedAgainst(
      searchParams.get("voted_against")
        ? searchParams.get("voted_against")!.split(",")
        : []
    );
    setResultBoolean(searchParams.get("result_boolean") || "");
    setIndiener(searchParams.get("indiener") || "");
  }, [location.search]);

  const handleSearch = () => {
    const filters = {
      min_date: startDate ? startDate.format("YYYY-MM-DD") : "",
      max_date: endDate ? endDate.format("YYYY-MM-DD") : "",
      voted_for: votedFor.join(","),
      voted_against: votedAgainst.join(","),
      result_boolean: resultBoolean === "both" ? "" : resultBoolean,
      indiener,
    };

    const params = new URLSearchParams();
    if (query) params.set("text", query);
    if (filters.min_date) params.set("startDate", filters.min_date);
    if (filters.max_date) params.set("endDate", filters.max_date);
    if (filters.voted_for) params.set("voted_for", filters.voted_for);
    if (filters.voted_against)
      params.set("voted_against", filters.voted_against);
    if (filters.result_boolean)
      params.set("result_boolean", filters.result_boolean);
    if (filters.indiener) params.set("indiener", filters.indiener);

    navigate(`/?${params.toString()}`);
    if (onSearch) {
      onSearch(query, {
        min_date: filters.min_date,
        max_date: filters.max_date,
        voted_for: votedFor,
        voted_against: votedAgainst,
        result_boolean: filters.result_boolean,
        indiener: filters.indiener,
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Paper
      sx={{
        padding: 4,
        boxShadow: 100,
        maxWidth: "500px",
        margin: "0 auto",
        width: "100%",
      }}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          label="Zoekopdracht"
          variant="outlined"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          fullWidth
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              label="Datum vanaf"
              value={startDate}
              slotProps={{
                actionBar: { actions: ["clear"] },
              }}
              onChange={(newValue) => setStartDate(newValue)}
            />
            <DatePicker
              format="DD/MM/YYYY"
              label="Datum tot en met"
              value={endDate}
              slotProps={{
                actionBar: { actions: ["clear"] },
              }}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </LocalizationProvider>
        </Box>
        <Autocomplete
          options={indieners}
          value={indiener}
          onChange={(event, newValue) => setIndiener(newValue || "")}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Indiener van de motie"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingIndieners ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          multiple
          options={parties}
          value={votedFor}
          onChange={(event, newValue) => setVotedFor(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Fracties die voor de motie hebben gestemd"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingParties ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          multiple
          options={parties}
          value={votedAgainst}
          onChange={(event, newValue) => setVotedAgainst(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Fracties die tegen de motie hebben gestemd"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingParties ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Uitkomst van de motie</InputLabel>
          <Select
            label="Uitkomst van de motie"
            value={resultBoolean}
            onChange={(e) => setResultBoolean(e.target.value)}
          >
            <MenuItem value="both">Alle uitkomsten</MenuItem>
            <MenuItem value="Aangenomen">Aangenomen</MenuItem>
            <MenuItem value="Verworpen">Verworpen</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Zoeken
        </Button>
      </Box>
    </Paper>
  );
};

export default SearchForm;
