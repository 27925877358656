import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link as RouterLink } from "react-router-dom";
import {
  Container,
  Typography,
  CircularProgress,
  Paper,
  Breadcrumbs,
  Link,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fetchSingleDocument, fetchSimilarDocuments } from "../services/api";
import VotesTable from "../components/VotesTable";
import DocumentList from "../components/DocumentList";
import ProgressBar from "../components/ProgressBar";

interface Document {
  document_nummer: string;
  datum: string;
  onderwerp: string;
  motie_samenvatting: string;
  stemming_uitslag: string;
  stemming_resultaat: string;
  link: string;
  stemming: Vote[];
  motie_text: string;
  indiener_fractie: string;
  indiener_naam: string;
}

interface Vote {
  ActorFractie: string;
  FractieGrootte: number;
  Soort: string;
  Vergissing?: boolean;
}

const DocumentDetailPage: React.FC = () => {
  const { documentId } = useParams<{ documentId: string }>();
  const location = useLocation();
  const from =
    location.state?.from?.pathname + location.state?.from?.search || "/";
  const [document, setDocument] = useState<Document | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [similarDocuments, setSimilarDocuments] = useState<Document[]>([]);

  useEffect(() => {
    const loadDocument = async () => {
      if (documentId) {
        try {
          const documentData = await fetchSingleDocument(documentId);
          setDocument(documentData);
          const similarDocs = await fetchSimilarDocuments(documentId);
          setSimilarDocuments(similarDocs);
        } catch (err) {
          setError("Failed to fetch document details");
        } finally {
          setLoading(false);
        }
      } else {
        setError("Document ID is undefined");
        setLoading(false);
      }
    };

    loadDocument();
  }, [documentId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [documentId]);

  const formatMotieText = (text: string) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line.split(";").map((part, i) => (
          <React.Fragment key={i}>
            <Typography variant="body1" component="span">
              {part.trim()}
              {i < line.split(";").length - 1 && ";"}
            </Typography>
            {i < line.split(";").length - 1 && <br />}
          </React.Fragment>
        ))}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <Container>
      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      {document && (
        <Box>
          <Paper
            sx={{
              padding: 4,
              boxShadow: 5,
              borderRadius: 2,
              maxWidth: "900px",
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              marginTop: 4,
              marginBottom: 10,
            }}
          >
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
              <Link
                component={RouterLink}
                to="/"
                underline="hover"
                color="inherit"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              </Link>
              <Link
                component={RouterLink}
                to={from}
                underline="hover"
                color="inherit"
              >
                Zoekopdracht
              </Link>
              <Typography color="textPrimary">Motie details</Typography>
            </Breadcrumbs>

            <Typography variant="h5" gutterBottom>
              {document.onderwerp}
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { sm: "center" },
                }}
              >
                {document.datum}
                <Box
                  component="span"
                  sx={{
                    display: { xs: "none", sm: "inline" },
                    marginX: "8px",
                  }}
                >
                  |
                </Box>
                <Link
                  href={document.link}
                  underline="hover"
                  sx={{
                    marginLeft: { xs: 0, sm: "8px" },
                    marginTop: { xs: "8px", sm: 0 },
                    fontSize: "0.875rem",
                  }}
                >
                  Link naar motie op tweedekamer.nl
                </Link>
              </Box>
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: 0 }}>
              Samenvatting
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              {document.motie_samenvatting}
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: 0 }}>
              Ingediend door
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              {document.indiener_naam}, {document.indiener_fractie}
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              {document.stemming_uitslag}
            </Typography>
            <ProgressBar
              value={parseInt(document.stemming_resultaat, 10)}
              max={150}
            />
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="votes-content"
                id="votes-header"
              >
                <Typography variant="h6">Stemmingen per fractie:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {document.stemming && <VotesTable votes={document.stemming} />}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="motie-content"
                id="motie-header"
              >
                <Typography variant="h6">Originele motie tekst</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {formatMotieText(document.motie_text)}
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Box>
      )}

      {similarDocuments.length > 0 && (
        <div>
          <Box
            sx={{
              maxWidth: "900px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100vh",
              margin: "auto",
            }}
          >
            {loading && <CircularProgress />}
            {error && <Typography color="error">{error}</Typography>}
            {!loading && !error && (
              <DocumentList
                documents={similarDocuments}
                title="Vergelijkbare moties:"
              />
            )}
          </Box>
        </div>
      )}
    </Container>
  );
};

export default DocumentDetailPage;
