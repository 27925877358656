// src/services/api.ts
import axios from 'axios';

//const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5000';
const API_URL = process.env.REACT_APP_API_URL || 'https://ikzoekeenmotie.nl/api';

interface PartyResponse {
  parties: string[];
}

interface IndienerResponse {
  indieners: string[];

}
interface DocumentResponse {
  document: any;
}

interface QueryDocumentsResponse {
  documents: any[];
}

interface QueryParams {
  text: string;
  min_date?: string;
  max_date?: string;
  voted_for?: string[];
  voted_against?: string[];
  result_boolean?: string;
  indiener?: string;
}

const handleApiError = (error: any) => {
  console.error('API Error:', error);
  throw error;
};

export const fetchUniqueParties = async (): Promise<string[]> => {
  try {
    const response = await axios.get<PartyResponse>(`${API_URL}/request_unique_parties`);
    return response.data.parties;
  } catch (error) {
    handleApiError(error);
    return [];
  }
};

export const fetchUniqueIndieners = async (): Promise<string[]> => {
  try {
    const response = await axios.get<IndienerResponse>(`${API_URL}/request_unique_indieners`);
    return response.data.indieners;
  } catch (error) {
    handleApiError(error);
    return [];
  }
};

export const fetchSingleDocument = async (documentId: string): Promise<any> => {
  try {
    const response = await axios.post<DocumentResponse>(`${API_URL}/request_document`, { document_nummer: documentId });
    return response.data.document;
  } catch (error) {
    handleApiError(error);
    return null;
  }
};

export const fetchQueryDocuments = async (queryData: QueryParams): Promise<any[]> => {
  try {
    const response = await axios.post<QueryDocumentsResponse>(`${API_URL}/request_query_documents`, queryData);
    return response.data.documents;
  } catch (error) {
    handleApiError(error);
    return [];
  }
};

export const fetchSimilarDocuments = async (documentId: string): Promise<any[]> => {
  try {
    const response = await axios.post<QueryDocumentsResponse>(`${API_URL}/request_similar_documents`, { document_nummer: documentId });
    return response.data.documents;
  } catch (error) {
    handleApiError(error);
    return [];
  }
};
