import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Link,
} from "@mui/material";
import { fetchQueryDocuments } from "../services/api";
import DocumentList from "../components/DocumentList";
import SearchForm from "../components/SearchForm";

interface Document {
  document_nummer: string;
  datum: string;
  onderwerp: string;
  motie_samenvatting: string;
  stemming_uitslag: string;
}

const HomePage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const hasSearchParams = searchParams.toString() !== "";

  useEffect(() => {
    const loadDocuments = async () => {
      const query = searchParams.get("text") || "";
      const min_date = searchParams.get("startDate") || "";
      const max_date = searchParams.get("endDate") || "";
      const voted_for = searchParams.get("voted_for")
        ? searchParams.get("voted_for")!.split(",")
        : [];
      const voted_against = searchParams.get("voted_against")
        ? searchParams.get("voted_against")!.split(",")
        : [];
      const result_boolean = searchParams.get("result_boolean") || "";
      const indiener = searchParams.get("indiener") || "";

      try {
        const documentsData = await fetchQueryDocuments({
          text: query,
          min_date,
          max_date,
          voted_for,
          voted_against,
          result_boolean,
          indiener,
        });
        setDocuments(documentsData);
      } catch (err) {
        setError("Failed to fetch documents");
      } finally {
        setLoading(false);
      }
    };

    loadDocuments();
  }, [location.search]); // Dependency array ensures the effect runs only when location.search changes

  return (
    <>
      <Box
        sx={{
          backgroundImage: "url(/images/tweedekamer-transformed3.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingBottom: 10,
          paddingTop: 5,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            color: "white",
          }}
        >
          <Link href="/about" underline="hover" style={{ color: "white" }}>
            Meer informatie
          </Link>
        </Box>

        <Box
          mb={4}
          sx={{
            textAlign: "center",
            maxWidth: "90%",
            margin: "0 auto",
            color: "white",
            padding: 2,
            "@media (min-width:600px)": {
              maxWidth: "60%",
            },
            "@media (min-width:960px)": {
              maxWidth: "40%",
            },
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            sx={{ fontWeight: "bold", mb: 4 }}
          >
            <img
              src="/images/ikzoekeenmotie_transparant.png"
              alt="Ikzoekeenmotie.nl"
              style={{ width: "50%", maxWidth: "250px", height: "auto" }}
            />
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Welkom op deze website, een tool om inzicht te krijgen in moties uit
            de Tweede Kamer waarop gestemd is. De website is een aanvulling op
            de officiële site,
            <Link
              href="https://tweedekamer.nl"
              underline="hover"
              style={{ marginLeft: "5px", color: "white" }}
            >
              tweedekamer.nl
            </Link>
            , en maakt het eenvoudiger om gericht moties te kunnen vinden. Met
            diverse filtermogelijkheden, zoekfunctie op context, en
            AI-gegenereerde samenvattingen, is in één oogopslag te zien waar een
            motie over gaat. Let op: de samenvattingen kunnen fouten bevatten.
            Begin hieronder met zoeken!
          </Typography>
        </Box>
        <Box
          mb={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "90%",
            margin: "0 auto",
            "@media (min-width:600px)": {
              maxWidth: "60%",
            },
            "@media (min-width:960px)": {
              maxWidth: "40%",
            },
          }}
        >
          <SearchForm />
        </Box>
      </Box>

      <Container>
        <Box
          sx={{
            maxWidth: "900px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            margin: "auto",
            marginTop: 5,
          }}
        >
          {loading && <CircularProgress />}
          {error && <Typography color="error">{error}</Typography>}
          {!loading && !error && (
            <DocumentList
              documents={documents}
              title={
                hasSearchParams
                  ? "Moties op basis van uw zoekopdracht:"
                  : "Meest recente moties:"
              }
            />
          )}
        </Box>
      </Container>
    </>
  );
};

export default HomePage;
