import React, { useState, useMemo } from "react";
import {
  Container,
  Typography,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

interface Document {
  document_nummer: string;
  datum: string;
  onderwerp: string;
  motie_samenvatting: string;
  stemming_uitslag: string;
}

interface DocumentListProps {
  documents: Document[];
  title: string;
}

const DocumentList: React.FC<DocumentListProps> = ({ documents, title }) => {
  const location = useLocation();
  const [sortCriteria, setSortCriteria] = useState<"relevance" | "date">(
    "relevance"
  );

  const sortedDocuments = useMemo(() => {
    return [...documents].sort((a, b) => {
      if (sortCriteria === "date") {
        const dateA = new Date(a.datum.split("-").reverse().join("-"));
        const dateB = new Date(b.datum.split("-").reverse().join("-"));
        return dateB.getTime() - dateA.getTime();
      }
      // Assuming 'relevance' is the default order of the array
      return 0;
    });
  }, [documents, sortCriteria]);

  const handleSortChange = (
    event: React.MouseEvent<HTMLElement>,
    newSortCriteria: "relevance" | "date"
  ) => {
    if (newSortCriteria !== null) {
      setSortCriteria(newSortCriteria);
    }
  };

  return (
    <Container>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" component="h2" gutterBottom>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} textAlign={{ xs: "left", sm: "right" }}>
          <ToggleButtonGroup
            value={sortCriteria}
            exclusive
            onChange={handleSortChange}
            aria-label="sort criteria"
            size="small"
          >
            <ToggleButton value="relevance" aria-label="sort by relevance">
              Relevantie
            </ToggleButton>
            <ToggleButton value="date" aria-label="sort by date">
              Datum
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <div style={{ marginTop: 20 }}>
        {sortedDocuments.map((document) => (
          <Link
            to={`/document/${document.document_nummer}`}
            state={{ from: location }}
            style={{ textDecoration: "none" }}
            key={document.document_nummer}
          >
            <Paper
              sx={{
                marginBottom: 4,
                padding: 4,
                boxShadow: 5,
                borderRadius: 2,
                cursor: "pointer",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.02)",
                  boxShadow: 6,
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "inherit" }}
              >
                {document.onderwerp}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 2 }}>
                {document.datum}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 2 }}>
                {document.motie_samenvatting}
              </Typography>
              <Typography variant="body1">
                {document.stemming_uitslag}
              </Typography>
            </Paper>
          </Link>
        ))}
      </div>
    </Container>
  );
};

export default DocumentList;
