// src/pages/NotFoundPage.tsx
import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CustomBox from "../components/CustomBox";

const NotFoundPage: React.FC = () => {
  return (
    <>
      <CustomBox infoText="Sorry, de pagina die u probeert te bezoeken bestaat niet." />
      <Container>
        <Box
          sx={{
            maxWidth: "900px",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "center",
            margin: "auto",
            marginTop: 10,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Mogelijk vindt u wat u zoekt via een van de volgende links:
          </Typography>
          <Typography paragraph>
            <Link component={RouterLink} to="/" underline="hover">
              Startpagina
            </Link>
          </Typography>
          <Typography paragraph>
            <Link component={RouterLink} to="/about" underline="hover">
              Informatie over de website
            </Link>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default NotFoundPage;
