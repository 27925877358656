import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";
import CustomBox from "../components/CustomBox";

const AboutPage: React.FC = () => {
  return (
    <>
      <CustomBox infoText="Hier staat algemene informatie over de website." />
      <Container>
        <Box
          sx={{
            maxWidth: "900px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            minHeight: "100vh",
            margin: "auto",
            marginTop: 0,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Welke informatie staat op de website?
          </Typography>
          <Typography paragraph>
            Op deze website staan alle moties waar stemmingen over zijn geweest
            sinds 2015. De site wordt dagelijks bijgewerkt met nieuwe moties en
            stemmingsresultaten.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Hoe worden de samenvattingen van de moties gegenereerd?
          </Typography>
          <Typography paragraph>
            De samenvattingen van de moties worden gegenereerd met behulp van
            AI. Hierbij wordt het onderwerp en de volledige tekst van de motie
            gebruikt om een samenvatting van drie zinnen te creëren. Omdat er
            ongeveer 30.000 moties zijn, zijn deze samenvattingen niet handmatig
            gecontroleerd en kunnen ze mogelijk fouten bevatten.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Hoe werkt het zoekproces op de website?
          </Typography>
          <Typography paragraph>
            Het zoeken naar moties werkt op twee manieren:
          </Typography>
          <Typography paragraph>
            BM25 Algoritme: Dit algoritme helpt te bepalen welke moties het
            beste overeenkomen met een zoekopdracht door rekening te houden met
            hoe vaak termen voorkomen, hoe uniek deze termen zijn, en de lengte
            van de moties.
          </Typography>
          <Typography paragraph>
            Semantisch zoeken: Deze methode gebruikt vectoren om semantische
            overeenkomsten tussen documenten en zoekopdrachten te vinden, in
            plaats van alleen letterlijke overeenkomsten zoals in BM25. Dit
            betekent dat bij de zoekopdracht "verbetering van het onderwijs",
            vector search ook moties kan identificeren met termen zoals
            "onderwijsinnovatie", "modernisering van scholen", of
            "opleidingsprogramma's verbeteren".
          </Typography>

          <Typography variant="h6" gutterBottom>
            Hoe worden de zoekresultaten gescoord en getoond?
          </Typography>
          <Typography paragraph>
            Bij een zoekopdracht wordt een score bepaald met behulp van beide
            algoritmen. BM25 draagt voor 90% bij aan de score en semantisch
            zoeken voor 10%. De 10 beste resultaten worden vervolgens getoond.
            De vergelijkbare moties die worden getoond bij het klikken op een
            motie, worden alleen bepaald door middel van het semantisch zoeken
            algoritme.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Is de website af?
          </Typography>
          <Typography paragraph>
            Dit is een eerste opzet van de website en in de toekomst hoop ik de
            zoekfunctionaliteit verder te kunnen verbeteren. Het zoekalgoritme
            geeft bijvoorbeeld nu altijd 10 resultaten terug. Bij zeer
            specifieke zoekopdrachten kan dit ervoor zorgen dat niet alle of
            zelfs geen resultaten relevant zijn.
          </Typography>
          <Typography paragraph>
            Bijvoorbeeld bij de zoekopdracht 'Vuurwerk afsteken bij oud en
            nieuw' voor moties na mei 2024, worden nog steeds 10 resultaten
            getoond, hoewel geen daarvan relevant is.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Bij andere vragen, opmerkingen of mogelijke ideeën voor de website?
          </Typography>
          <Typography paragraph>
            Stuur dan een bericht via{" "}
            <Link
              href="https://linkedin.com/in/coen-goedhart"
              underline="hover"
            >
              LinkedIn
            </Link>
            .
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default AboutPage;
