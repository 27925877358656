import React from "react";
import { Box, Typography } from "@mui/material";

interface ProgressBarProps {
  value: number;
  max: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value, max }) => {
  const calculateBarWidth = (value: number, max: number) => {
    const percentage = (value / max) * 100;
    return percentage;
  };

  return (
    <Box sx={{ position: "relative", marginBottom: 10 }}>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#e0e0e0",
          height: 20,
          borderRadius: 10,
        }}
      >
        <Box
          sx={{
            width: `${calculateBarWidth(value, max)}%`,
            backgroundColor: "#000080", // Change color as needed
            height: "100%",
            borderRadius: "inherit",
            textAlign: "right",
            paddingRight: 1,
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {value}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "absolute",
          top: "100%",
          width: "100%",
          transform: "translateY(10px)",
        }}
      >
        <Typography variant="body2">0</Typography>
        <Typography
          variant="body2"
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          75
        </Typography>
        <Typography variant="body2">150</Typography>
      </Box>
    </Box>
  );
};

export default ProgressBar;
