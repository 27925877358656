import React from "react";
import { Box, Typography, Link } from "@mui/material";

interface CustomBoxProps {
  infoText: string;
}

const CustomBox: React.FC<CustomBoxProps> = ({ infoText }) => {
  return (
    <Box
      sx={{
        backgroundImage: "url(/images/tweedekamer-transformed3.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingBottom: 10,
        paddingTop: 5,
      }}
    >
      <Box
        mb={4}
        sx={{
          textAlign: "center",
          maxWidth: "90%", // Adjust the max-width for better responsiveness
          margin: "0 auto",
          color: "white",
          padding: 2, // Add padding for better spacing
          "@media (min-width:600px)": {
            maxWidth: "60%", // Adjust based on your preference
          },
          "@media (min-width:960px)": {
            maxWidth: "40%",
          },
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 4 }}
        >
          <Link href="/" underline="hover" color="inherit">
            <img
              src="/images/ikzoekeenmotie_transparant.png"
              alt="Ikzoekeenmotie.nl"
              style={{ width: "50%", maxWidth: "250px", height: "auto" }}
            />
          </Link>
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {infoText}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomBox;
